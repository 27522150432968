/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-header {
    ion-toolbar {
        ion-icon {
            font-size: 25px;
            padding-left: 10px;
        }
    }
}

.selectable-text {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

ion-menu {
    ion-item {
        cursor: pointer;
    }

    ion-label.menu-text {
        padding-left: 15px;
    }

    ion-label.sub-text {
        padding-left: 25px;
    }

    .item-block {
        min-height: 5.5rem;
    }

    .menu-main {
        font-size: 18px;
    }

    .menu-disabled {
        color: #999999;
    }

    .menu-base-language {
        color: #999999;
        text-transform: uppercase;
        margin-left: 5px;
    }

    .menu-hide {
        display: none;
    }

    .menu-logo-url-container {
        text-align: center;
        position: relative;
        margin-bottom: -7px;
    }
    .md .menu-logo-url-container {
        top: -7px;
    }
    .ios .menu-logo-url-container {
        top: 0px;
    }

    .menu-item-kids {
        font-weight: 700;
        font-size: 1.1em;
        letter-spacing: 2px;
        text-shadow: 0px 1px 2px rgba(255,255,255,1);
        .kids-letter-1 {
            color: #2688c8;
        }
        .kids-letter-2 {
            color: #97b72b;
        }
        .kids-letter-3 {
            color: #e93636;
        }
        .kids-letter-4 {
            color: #decd33;
        }
    }

    .submenu-container {
        height: 0px;
        opacity: 0;
        overflow: hidden;
    }
}


.activeMenuHighlight {
    --ion-background-color: #ececec;
    /*font-weight: bold;*/
}

/* Could be done with a variable in the future? https://github.com/driftyco/ionic/issues/10893 */
.split-pane-visible>.split-pane-side {
    max-width: 22% !important;
}

/* This is because in firefox, message box has scroll bar on the side even when empty */
.alert-message {
    overflow-y: auto !important;
}

/*
 * This adds a scroll bar to ionic alerts
 * See https://gitlab.com/uugotitTeam/uugotit-webapp2/issues/39
 */
.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar,
.alert-message::-webkit-scrollbar {
    width: 0.7em;
    display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track,
.alert-message::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb,
.alert-message::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

/* === START alert classes === */
.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}

.alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
}

.alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca;
}
/* === END alert classes === */

.content-infotext {
    color: #818182;
    font-size: 0.93em;
}

.websource-logo-name, .subjects-list, .educator-name {
    color: #000;
    font-size: 12px;
    padding: 2px 0px;
    user-select: none;
    display: inline-block;
}

ion-alert div.terms-of-use-alert {
    color: #222;
}

/* === START left/right arrows (flash-cards, word-catalog-cards, tutorial) === */

.slide-arrows-container {
    ion-icon {
        position: absolute;
        top: calc(50vh - 68px);
        z-index: 10;
        font-size: 70px;
        color: rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }

    ion-icon.slide-back-arrow {
        left: 3%;
    }

    ion-icon.slide-forward-arrow, ion-icon.slide-done-arrow {
        right: 3%;
    }
}
/* === END left/right arrows (flash-cards, word-catalog-cards, tutorial) === */

ion-label.validator-errors {
    p {
        margin: 3px 0px;
    }
}

ion-alert.dialog-registration-not-possible {
    .footer {
        color: white;
        background-color: #888;
        font-size: 0.93em;
        padding: 1px 4px;
    }
}

ion-loading.video-translation-wait-dialog {
    // --background: #222;
    // --spinner-color: #fff;
    // color: #fff;
    .text-message {
        min-width: 150px;
    }
    .text-percent {
        font-size: 1.5em;
        font-weight: bold;
        margin-top: 10px;
    }
}

.bottomright-floating-button-container {
    position: absolute;
    right: 10px;
    bottom: 10px;
    ion-button {
        height: 44px;
        opacity: 0.85;
    }
    .floating-close-button-background {
        position: absolute;
        top: 0px;
        right: -1px;
        width: 14px;
        height: 14px;
        background-color: #eee;
        border-radius: 50%;
    }
    .floating-close-button {
        position: absolute;
        top: -3px;
        right: -4px;
        font-size: 20px;
        border-radius: 50%;
        color: #444;
    }
}

ion-popover.nextVideo-dialog .popover-content {
    min-width: 95%;
}

// "Embed code" popover
@media (min-width: 460px) {
    ion-popover.embed-dialog .popover-content {
        min-width: 440px;
    }

    ion-popover.nextVideo-dialog .popover-content {
        min-width: 440px;
    }
}

// Style for @logisticinfotech/ionic4-datepicker
.li-ionic4-datePicker {
    .modal-wrapper {
        height: 470px;
        max-height: 100%;
        width: 330px;
    }
}

@media (min-width: 768px) {
    ion-modal.auto-height {
        --background: var(--ion-background-color, rgba(0, 0, 0, 0)) !important ;
        --box-shadow: none;
        --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
        &.bottom {
            align-items: flex-end;
        }

        --height: auto;

        .ion-page {
            position: relative;
            display: block;
            contain: content;

            .inner-content {
                max-height: 80vh;
                overflow: auto;
                padding: 10px;
            }
        }
    }
}


ion-modal.modal-premium-feature-gif {
    --width: fit-content;
    --min-width: 250px;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal.modal-premium-feature-gif h1 {
    margin: 20px 20px 10px 20px;
}

ion-modal.modal-premium-feature-gif ion-icon {
    margin-right: 6px;

    width: 48px;
    height: 48px;

    padding: 4px 0;

    color: #aaaaaa;
}

ion-modal.modal-premium-feature-gif .wrapper {
    margin-bottom: 10px;
}

ion-modal.videoEmbedCode {
    //--width: fit-content;
    //--min-width: 400px;
    //--height: 400px;
}
