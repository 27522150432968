// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    --text-color: #000;
    --background-color: #fff;

    /** Our own theme - this is the Material Design cyan color: https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=00ACC1 **/
    --ion-color-primary: #00acc1;
    --ion-color-primary-rgb: 0,172,193;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #00838f;
    --ion-color-primary-tint: #26c6da;

    --ion-color-secondary: #ffeb3b;
    --ion-color-secondary-rgb: 255,235,59;
    --ion-color-secondary-contrast: #222;
    --ion-color-secondary-contrast-rgb: 0,0,0;
    --ion-color-secondary-shade: #ffe81a;
    --ion-color-secondary-tint: #fff066;

    /*
    --ion-color-danger: #f53d3d;

    --ion-color-light: #f4f4f4;

    --ion-color-dark: #222;
    */

    --ion-color-favorite-base: #69BB7B;
    --ion-color-favorite-base-rgb: 105,187,123;
    --ion-color-favorite-contrast: #222;
    --ion-color-favorite-contrast-rgb: 34,34,34;
    --ion-color-favorite-shade: #479e5a;
    --ion-color-favorite-tint: #95d0a2;

    --ion-color-blue-base: #3BB1FF;
    --ion-color-blue-base-rgb: 59,177,255;
    --ion-color-blue-contrast: #fff;
    --ion-color-blue-contrast-rgb: 255,255,255;
    --ion-color-blue-shade: #1aa3ff;
    --ion-color-blue-tint: #66c2ff;

    --ion-color-green-base: #B2D832;
    --ion-color-green-base-rgb: 178,216,50;
    --ion-color-green-contrast: #222;
    --ion-color-green-contrast-rgb: 34,34,34;
    --ion-color-green-shade: #9cc125;
    --ion-color-green-tint: #bede54;

    --ion-color-white-base: #fff;
    --ion-color-white-base-rgb: 255,255,255;
    --ion-color-white-contrast: #222;
    --ion-color-white-contrast-rgb: 34,34,34;
    --ion-color-white-shade: #e6e6e6;
    --ion-color-white-tint: #fff;


    // Customize grid width (see https://ionicframework.com/docs/layout/grid#grid-width):
    // --ion-grid-width-xl: 1250px;

    --app-color-dirtyyellow: #dede25;
    --app-color-dirtyyellow-semitransparent: rgba(222, 222, 37, 0.67);
    --app-color-dirtyyellow-semitransparent-75: rgba(222, 222, 37, 0.75);

    --app-leaderboard-color-green: #2da842;
    --app-leaderboard-color-yellow: #dcd31a;
    --app-leaderboard-color-orange: #dc681a;
    --app-leaderboard-color-red: #dc1c1a;
}

/** Make colors available through [color] directive **/
$customColors: ('favorite', 'blue', 'green', 'white');

@each $color in $customColors {
    .ion-color-#{$color} {
        --ion-color-base: var(--ion-color-#{$color}-base) !important;
        --ion-color-base-rgb: var(--ion-color-#{$color}-base-rgb) !important;
        --ion-color-contrast: var(--ion-color-#{$color}-contrast) !important;
        --ion-color-contrast-rgb: var(--ion-color-#{$color}-contrast-rgb) !important;
        --ion-color-shade: var(--ion-color-#{$color}-shade) !important;
        --ion-color-tint: var(--ion-color-#{$color}-tint) !important;
    }
}
